<template>
  <div>
    <vue-breadcrumbs :breadCrumbs="breadCrumbs" />
    <today-conditions :info="info" />
    <div class="d-inline-block">
      <v-row>
        <v-col :cols="6">
          <v-select
          v-model="action"
          :items="actions"
          label="Действие"
          @change="handleChange"
        ></v-select>
        </v-col>
        <v-col :cols="6">
          <v-btn color="accent" class="ma-2" v-if="isBlockOption == 'block'" @click="blockSeats">Заблокировать</v-btn>
          <v-btn color="accent" class="ma-2" v-if="isBlockOption == 'unblock'" @click="blockSeats">Разблокировать</v-btn>
          <v-btn color="primary" class="ma-2" @click="isBlockOption == '' ? isBlockOption = action : isBlockOption = '' " v-text="isBlockOption != '' ? 'Отмена' : 'Выбрать несколько'">Отмена</v-btn>
        </v-col>
      </v-row>
    </div>
    <bus-model
      ref="slepping"
      @openPopup="openPopup"
      :popupOpen="true"
      :selectable="true"
      :isSleeping="true"
      :isBlockOption="isBlockOption"
    />
    <v-dialog v-model="popup" max-width="700">
      <v-card class="pa-8">
        <h2 class="title mb-4">Информация о брони</h2>
        <h4 class="price" v-text="`№ ${item.id}`"></h4>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col :cols="6" v-for="(input, index) in inputItems" :key="index">
              <v-text-field
                :label="input.label"
                :value="item[input.model]"
                readonly
                height="44"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-btn @click="blockSeats" class="mt-4" v-text="item.is_blocked_sp ? 'Разблокировать' : 'Заблокировать'" color="primary"></v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vueBreadcrumbs from "@/components/ui/vueBreadcrumbs.vue";
import TodayConditions from "@/components/ui/todayConditions.vue";
import VueTableActions from "@/components/ui/vueTableActions.vue";
import { dynamicService } from "@/services/salesDynamic.service.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import BusModel from "@/components/buses/busModel.vue";
import axios from "axios";
import moment from 'moment'
export default {
  components: {
    vueBreadcrumbs,
    TodayConditions,
    VueTableActions,
    BusModel,
  },
  data() {
    return {
      info: {},
      popup: false,
      item: {},
      action: '', 
      actions: [
        {
          text: 'Заблокировать',
          value: 'block'
        },
        {
          text: 'Разблокировать',
          value: 'unblock'
        },
      ],
      breadCrumbs: [
        {
          text: "Динамика продаж блоков",
          to: "/administration/sales",
        },
        {
          text: "Автобус",
          to: "/administration/sales/buses",
        },
        {
          text: "Детали",
          to: `/administration/sales/buses/${this.$route.params.id}`,
          disabled: true,
        },
      ],
      inputItems: [
        {
          label: "Турагент",
          model: "tour_agent_or_operator",
        },
        {
          label: "ФИО туриста",
          model: "client_name",
        },
        {
          label: "Номер места",
          model: "name",
        },
        {
          label: "Тип места",
          model: "seat_place_type",
        },
        {
          label: "Стоимость",
          model: "price",
        },
        {
          label: "Тип тарифа",
          model: "tariff",
        },
      ],
      blocked_seats: [],
      loading: false,
      isBlockOption: '', 
    };
  },
  computed: {
    ...mapGetters({
      busModel: "busModel/getBusModel",
    }),
  },
  watch: {
    popup(){
      if(!this.popup) this.item = {}
    }
  },
  created() {
    this.getInfo();
    this.getStat();
  },
  methods: {
    ...mapMutations({
      translateFromBack: "busModel/TRANSFORM_BUS_MODEL_FROM_BACK",
    }),
    handleChange(value){
      if(this.isBlockOption != '') this.isBlockOption = value
    },
    async blockSeats() {
      let arr = this.$copy(this.$refs.slepping.blockedSeats)
      console.log(arr, this.item);
      let some_or_one = this.item.hasOwnProperty('id') 
      let body = {
        route: +this.$route.params.id,
        seat_places: some_or_one ? [ this.item.is_blocked_sp ? this.item.id : { id: this.item.id } ] : this.isBlockOption == 'unblock' ? arr.map(e => e.id) : arr.map(e => { return { id: e.id } })
      };
      this.loading = true;
      if(some_or_one ? this.item.is_blocked_sp : this.isBlockOption == 'unblock') await axios.post(`/unblock-seat-places/`, body);
      else await axios.post(`/block-seat-places/`, body);
      this.loading = false;
      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: `Место ${this.item.is_blocked_sp ? 'разблокировано' : 'заблокировано'}!`,
        color: "success",
      });
      this.popup = false;
      this.getInfo();
      this.getStat();
    },
    async getStat() {
      let res = await dynamicService.getToday(
        {
          from_date: this.$route.query.date || moment(new Date()).format('YYYY-MM-DD'),
          from_location: this.$route.query.from_location || null,
          to_location: this.$route.query.to_location || null,
          bus_id: this.$route.query.bus_id,
        },
        "bus"
      );
      this.info = res;
    },
    openPopup(seat) {
      this.item = { ...seat };
      if (!this.item.client_name) {
        this.item.seat_place_type = "Руль";
        this.item.name = "Руль";
      } else if (this.item.seat_place_type == "door") {
        this.item.seat_place_type = "Лестница";
        this.item.name = "Лестница";
      } else {
        this.item.seat_place_type = "Сидение";
      }
      this.popup = true;
    },
    async getInfo() {
      this.$loading(true)
      let res = await dynamicService.getConcrete(
        {
          bus_route_id: this.$route.params.id,
          bus_id: this.$route.query.bus_id,
        },
        "bus"
      );
      res.bus_seat_places = res.results;
      this.translateFromBack(res);
      this.$loading(false)
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #52b1ba;
}
.price {
  color: #ff8413;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
</style>
